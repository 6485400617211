.App {
  text-align: center;
  width: 370px;
  margin: 0 auto;
}

.key-highlight {
  color: #8b8d36
}

.key-disabled {
  color: #CCC
}
.footer {
  position:absolute;
  left:0;
  bottom:0;
  right:0;
  margin: 0 4px 4px 4px;
}
.key-info {
  background: url("./images/info-button.png") no-repeat center center;
  height: 20px;
  background-size: cover;
}
